import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import Page404 from '../pages/Pagina404';
import Enviado from '../pages/Enviado';
import Cadastro from '../pages/Cadastro';
import Login from '../pages/Login';
import ProtectedRoute from './protectRoute';


function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/:id' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path='/enviado' element={<Enviado />} />
                
                <Route element={<ProtectedRoute />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/cadastro' element={<Cadastro />} />
                </Route>

                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RoutesApp
