import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

function Sessao1({ data }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Quantidade avaliações
            </Typography>
            <Typography color="textSecondary">
              {data.dados.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Satisfeitos
            </Typography>
            <Typography color="textSecondary">
              {data.satisfeito}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Insatisfeitos
            </Typography>
            <Typography color="textSecondary">
              {data.insastifeito}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Sessao1;
