import UserProvider from "./contexts/user";
import RoutesApp from "./routes/routes";

function App() {
  return (
    <UserProvider>
      <RoutesApp />
    </UserProvider>

  );
}

export default App;
