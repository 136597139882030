import React, { useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';

function Sessao1({ onSubmit }) {
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ id:parseInt(codigo), nome });
    setCodigo('');
    setNome('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Código"
            variant="outlined"
            fullWidth
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Sessao1;
