import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Sessao3({ dataInicial, dataFinal, setDataInicial, setDataFinal, tableRows, page, rowsPerPage, handleChangePage, handleClickOpen }) {
  return (
    <Card>
      <>Data inicial: </>
      <input
        type="date"
        value={dataInicial}
        onChange={(e) => setDataInicial(e.target.value)}
      />
      <>Data Final: </>
      <input
        type="date"
        value={dataFinal}
        onChange={(e) => setDataFinal(e.target.value)}
      />
      <CardContent>
        <Typography variant="h5" component="h2">
          Registros
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Qualidade</TableCell>
                <TableCell>Local</TableCell>
                <TableCell>Atendimento</TableCell>
                <TableCell>Observação</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.cliente_id}</TableCell>
                  <TableCell>{row.cliente["nome"]}</TableCell>
                  <TableCell>{row.qualidade}</TableCell>
                  <TableCell>{row.local}</TableCell>
                  <TableCell>{row.atendimento}</TableCell>
                  <TableCell>{row.observacao}</TableCell>
                  <TableCell>{row.data}</TableCell>
                  <TableCell>
                    <IconButton color="error" onClick={() => handleClickOpen(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={tableRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default Sessao3;
