import {
    Container,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Button,
    Grid,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import logo from '../../img/logo.png';
import { setupAPIClient } from '../../services/api';

const theme = createTheme({
    palette: {
        primary: {
            main: '#228B22', // Define a cor verde como a cor primária
        },
        secondary: {
            main: '#ff5722', // Adicione uma cor secundária se necessário
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Exemplo: remover a transformação de texto dos botões
                },
            },
        },
    },
});

function Home() {
    const apiClient = setupAPIClient();
    const { id } = useParams();
    const navigate = useNavigate();

    const [connectionQuality, setConnectionQuality] = useState('');
    const [routerLocation, setRouterLocation] = useState('');
    const [technicianExperience, setTechnicianExperience] = useState('');
    const [comments, setComments] = useState('');

    const [formErrors, setFormErrors] = useState({
        connectionQuality: false,
        routerLocation: false,
        technicianExperience: false,
    });

    const connectionQualityRef = useRef(null);
    const routerLocationRef = useRef(null);
    const technicianExperienceRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const errors = {
            connectionQuality: !connectionQuality,
            routerLocation: !routerLocation,
            technicianExperience: !technicianExperience,
        };

        setFormErrors(errors);

        if (Object.values(errors).includes(true)) {
            if (errors.connectionQuality && connectionQualityRef.current) {
                connectionQualityRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (errors.routerLocation && routerLocationRef.current) {
                routerLocationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (errors.technicianExperience && technicianExperienceRef.current) {
                technicianExperienceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }

       /* console.log({
            connectionQuality,
            routerLocation,
            technicianExperience,
            comments,
            id,
        });*/

        try {
            await apiClient.post("/api/avaliacao", {
                qualidade: parseInt(connectionQuality),
                local: parseInt(routerLocation),
                atendimento: parseInt(technicianExperience),
                observacao: comments,
                cliente_id: parseInt(id),
            });
            console.log("passou");
            navigate('/enviado');
        } catch (error) {
            console.log("error" + error);
        }
    };

    const verificarAvaliacao = async () => {
        let r = await apiClient.get("/api/verificaravaliacao/" + id);
        console.log(r.data);
        if (r.data === 0) {
            navigate('/enviado');
            console.log("entrou navigate");
        } else if (r.data == 66) {
            navigate('/');
        }
    };

    useEffect(() => {
        verificarAvaliacao();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Pesquisa de Satisfação
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl
                        component="fieldset"
                        margin="normal"
                        fullWidth
                        required
                        error={formErrors.connectionQuality}
                        ref={connectionQualityRef}
                    >
                        <FormLabel component="legend">Como está a qualidade da sua conexão atualmente?</FormLabel>
                        <RadioGroup
                            aria-label="connectionQuality"
                            name="connectionQuality"
                            value={connectionQuality}
                            onChange={(e) => setConnectionQuality(e.target.value)}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Ótima, como o esperado!" />
                            <FormControlLabel value="2" control={<Radio />} label="Estou com problemas na minha conexão!" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl
                        component="fieldset"
                        margin="normal"
                        fullWidth
                        required
                        error={formErrors.routerLocation}
                        ref={routerLocationRef}
                    >
                        <FormLabel component="legend">Seu aparelho roteador foi instalado no local exato que você solicitou?</FormLabel>
                        <RadioGroup
                            aria-label="routerLocation"
                            name="routerLocation"
                            value={routerLocation}
                            onChange={(e) => setRouterLocation(e.target.value)}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Sim, conforme solicitei!" />
                            <FormControlLabel value="2" control={<Radio />} label="Não, pois o técnico me explicou que o local que eu deseja que fosse instalado poderia ocorrer problemas futuros com a minha conexão. Entendi, e aceitei a instalação no local recomendado por ele!" />
                            <FormControlLabel value="3" control={<Radio />} label="Não fiquei satisfeito com a instalação!" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl
                        component="fieldset"
                        margin="normal"
                        fullWidth
                        required
                        error={formErrors.technicianExperience}
                        ref={technicianExperienceRef}
                    >
                        <FormLabel component="legend">Como foi sua experiência com o atendimento do técnico durante a instalação do serviço?</FormLabel>
                        <RadioGroup
                            aria-label="technicianExperience"
                            name="technicianExperience"
                            value={technicianExperience}
                            onChange={(e) => setTechnicianExperience(e.target.value)}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Atendimento excelente!" />
                            <FormControlLabel value="2" control={<Radio />} label="Atendimento razoável, poderia melhorar!" />
                            <FormControlLabel value="3" control={<Radio />} label="Atendimento ruim!" />
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        id="comments"
                        label="Observações (opcional)"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />

                    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', padding: '16px', boxShadow: '0 -2px 8px rgba(0,0,0,0.1)' }}>
                        <Container maxWidth="sm">
                            <Grid container justifyContent="flex-end">
                                <Button variant="contained" color="primary" type="submit" endIcon={<SendIcon />} style={{width:'100%'}}>
                                    Enviar
                                </Button>
                            </Grid>
                        </Container>
                    </div>
                </form>
                <br/><br/><br/><br/><br/><br/>
            </Container>
        </ThemeProvider>
    );
}

export default Home;
