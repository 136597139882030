import React from 'react';
import { AppBar, Drawer, List, ListItem, ListItemText, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Menu() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    const appBarStyle = {
        zIndex: 1300,
    };

    return (
        <div>
            <AppBar position="fixed" style={appBarStyle}>
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                style={{ marginRight: 100 }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar />
                <List>
                    <ListItem button onClick={() => handleNavigation('/dashboard')}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigation('/cadastro')}>
                        <ListItemText primary="Cadastro" />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}

export default Menu;
