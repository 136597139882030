import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import Sessao1 from './sessao1';
import Sessao2 from './sessao2';
import Menu from '../../components/menu';
import { setupAPIClient } from '../../services/api';

function Cadastro() {
    const apiClient = setupAPIClient();
    const [clientes, setClientes] = useState([]);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    useEffect(() => {
        fetchClientes();
    }, []);

    const fetchClientes = async () => {
        try {
            const response = await apiClient.get('/api/cliente');
            setClientes(response.data);
        } catch (error) {
            console.error('Erro ao buscar clientes:', error);
        }
    };

    const handleCadastro = async (cliente) => {
        try {
            await apiClient.post('/api/cliente', cliente);
            fetchClientes();
        } catch (error) {
            console.error('Erro ao cadastrar cliente:', error);
        }
    };

    const handleDelete = async (codigo) => {
        try {
            await apiClient.delete(`/api/cliente/${codigo}`);
            fetchClientes();
        } catch (error) {
            console.error('Erro ao excluir cliente:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <Menu/>
            <br/><br/><br/>
            <Container>
                <Typography variant="h4" component="h1" gutterBottom>
                    Cadastro de Cliente
                </Typography>

                <Sessao1 onSubmit={handleCadastro} />

                <Typography variant="h5" component="h2" gutterBottom style={{ marginTop: '20px' }}>
                    Clientes Cadastrados
                </Typography>

                <Sessao2
                    clientes={clientes}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleDelete={handleDelete}
                />
            </Container>
        </div>
    );
}

export default Cadastro;
