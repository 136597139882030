import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function Sessao2({ chartData }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          Gráfico de Avaliações por Dia
        </Typography>
        <LineChart width={900} height={300} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="count" stroke="#8884d8" />
        </LineChart>
      </CardContent>
    </Card>
  );
}

export default Sessao2;
