
import { Container, Grid, Card, CardContent, Typography, useMediaQuery, useTheme, Box, Link } from '@mui/material';
import { Done as DoneIcon, Phone as PhoneIcon, LocationOn as LocationOnIcon, Info as InfoIcon } from '@mui/icons-material';

import avatar from '../../img/avatar.png'
import anatel from '../../img/anatel.png'

function Enviado() {
    const forestGreen = '#228B22';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div style={{ backgroundColor: "#F0FFF0" }}>


            <Container>
                
                <Typography style={{ color: '#006400' }} variant="h4" component="h1" align="center" gutterBottom>
                    A sua pesquisa de satisfação foi enviada
                </Typography>

                {/* Logo */}
                <Grid container justifyContent="center" marginBottom={4}>
                    <img src={avatar} alt="Logo" style={{ width: isMobile ? '90%' : '50%', height: 'auto' }} />
                </Grid>

                {/* Cards */}
                <Grid container spacing={3} justifyContent="center" marginBottom={4}>
                    {/* Card 1 */}
                    <Grid item xs={12} sm={10} md={4}>
                        <Card style={{ backgroundColor: forestGreen, color: '#fff', textAlign: 'center' }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">

                                    <Typography variant="h6" gutterBottom>
                                        <DoneIcon style={{ color: '#fff', fontSize: 25 }} />
                                        RETIRE SEU BOLETO:
                                    </Typography>
                                    <Typography>
                                        <Link href="https://central.megalinkvv.net.br" target="_blank" color="#fff" underline="hover">
                                            central.megalinkvv.net.br
                                        </Link>

                                        <br />
                                        Ou se dirija ao nosso escritório
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Card 2 */}
                    <Grid item xs={12} sm={10} md={4}>
                        <Card style={{ backgroundColor: forestGreen, color: '#fff', textAlign: 'center' }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">

                                    <Typography variant="h6" gutterBottom>
                                        <PhoneIcon style={{ color: '#fff', fontSize: 25 }} />
                                        CONTATOS:
                                    </Typography>
                                    <Typography>
                                        <Link href="https://api.whatsapp.com/send?phone=27992784038&text=Megalink" target="_blank" color="#fff" underline="hover">
                                            WhatsApp: (27) 99278-4038
                                        </Link>

                                        <br />
                                        Ligação: (27) 3150-9160
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Card 3 */}
                    <Grid item xs={12} sm={10} md={4}>
                        <Card style={{ backgroundColor: forestGreen, color: '#fff', textAlign: 'center' }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">

                                    <Typography variant="h6" gutterBottom>
                                        <LocationOnIcon style={{ color: '#fff', fontSize: 25 }} />
                                        Nosso endereço:
                                    </Typography>
                                    <Typography>
                                        Rua Nelson Monteiro, 31 - Ibes - Vila Velha

                                    </Typography>
                                    <br></br>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Guia do Cliente */}
                <Typography variant="h5" component="h2" align="center" gutterBottom>
                    Guia do Cliente:
                </Typography>
                <Typography component="ul" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                    <li><InfoIcon style={{ color: forestGreen }} /> Não instale o roteador em locais fechados, próximos a espelhos ou em cima de equipamentos eletrônicos, entre outros.</li>
                    <li><InfoIcon style={{ color: forestGreen }} /> Para ambientes grandes e com muitas pessoas, às vezes é necessário instalar mais de 1 roteador.</li>
                    <li><InfoIcon style={{ color: forestGreen }} /> Equipamentos como TV, Videogames, IPTV e outros, têm um melhor desempenho quando conectados via cabo.</li>
                    <li><InfoIcon style={{ color: forestGreen }} /> Para planos acima de 100 megas, serão necessários equipamentos que funcionam com sinal 5.8 GHz.</li>
                    <li><InfoIcon style={{ color: forestGreen }} /> Para planos acima de 500 megas o teste de velocidade precisa ser realizado através do cabo.</li>
                    <li><InfoIcon style={{ color: forestGreen }} /> A velocidade de conexão entregue no Wi-Fi é de até 500Mbps e, pela porta Ethernet, a velocidade entregue é de até 1Gbps, de acordo com o plano contratado e os equipamentos compatíveis com a tecnologia de 5.8GHz do assinante.</li>
                </Typography>



            </Container>
            <br />
            {/* Rodapé */}
            <footer style={{ backgroundColor: "#000", color: '#fff', padding: '20px 0', textAlign: 'center' }}>
                <Container>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>

                        <Grid item>
                            <Typography variant="body2">
                                <Link href="https://privacidade.megalink.net.br" target="_blank" color="#fff" underline="hover">
                                    Política de Privacidade
                                </Link>
                                <br />
                                © SUPERNETMAIS TELECOMUNICACOES LTDA
                                <br />
                                31.175.922/0001-63
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={anatel} alt="Logo Anatel" style={{ width: '50%', height: 'auto' }} />
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        </div>
    )

}

export default Enviado;