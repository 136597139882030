import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Sessao1 from './sessao1';
import Sessao2 from './sessao2';
import Sessao3 from './sessao3';
import Menu from '../../components/menu';
import { setupAPIClient } from '../../services/api';

function Dashboard() {
  const apiClient = setupAPIClient();
  const [page, setPage] = useState(0);
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [data, setData] = useState({ dados: [] });
  const rowsPerPage = 10;
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (dataInicial === '') {
      mesAtual();
    }
    filtroAvaliacao();
  }, []);

  useEffect(() => {
    filtroAvaliacao();
  }, [dataInicial, dataFinal]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleConfirmDelete = () => {
    deletarAvaliacao(selectedId);
    setOpen(false);
  };

  const mesAtual = async () => {
    const currentDate = new Date();
    const firstDayOfMonth = startOfMonth(currentDate);
    const lastDayOfMonth = endOfMonth(currentDate);

    const formattedFirstDay = format(firstDayOfMonth, 'yyyy-MM-dd', { locale: ptBR });
    const formattedLastDay = format(lastDayOfMonth, 'yyyy-MM-dd', { locale: ptBR });
    setDataInicial(formattedFirstDay);
    setDataFinal(formattedLastDay);
  };

  const filtroAvaliacao = async () => {
    try {
      const response = await apiClient.post("/api/filtroavaliacao", {
        dataInicial: dataInicial,
        dataFinal: dataFinal,
      });

      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const deletarAvaliacao = async (id) => {
    try {
      await apiClient.delete("/api/deletaravaliacao/" + id);
      filtroAvaliacao();
    } catch (error) {
      console.log(error);
    }
  };

  const dataForChart = data.dados.reduce((acc, item) => {
    const date = new Date(item.data).toISOString().split('T')[0];
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const chartData = Object.keys(dataForChart).map(date => ({
    date,
    count: dataForChart[date]
  }));

  const tableRows = data.dados.map(row => ({
    ...row,
    qualidade: row.qualidade === 1 ? 'ótima' : 'ruim',
    local: row.local === 1 ? 'ótimo' : row.local === 2 ? 'bom' : 'ruim',
    atendimento: row.atendimento === 1 ? 'ótima' : row.atendimento === 2 ? 'regular' : 'ruim'
  }));

  const appBarStyle = {
    zIndex: 1300,
  };

  return (
    <div style={{ display: 'flex' }}>
      
      <Menu />

      <main style={{ flexGrow: 1, backgroundColor: '#f0f0f0', padding: '20px' }}>
        <Toolbar />

        <Container maxWidth="lg">
          <Sessao1 data={data} />
          <br />
          <Sessao2 chartData={chartData} />
          <br />
          <Sessao3
            dataInicial={dataInicial}
            dataFinal={dataFinal}
            setDataInicial={setDataInicial}
            setDataFinal={setDataFinal}
            tableRows={tableRows}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleClickOpen={handleClickOpen}
          />
        </Container>
      </main>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar Exclusão"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza de que deseja excluir este item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
