import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



const Page404 = () => {
  const navigate = useNavigate();

  const handleWhatsAppClick = () => {
    const whatsappNumber = '5527992784038'; // Substitua pelo número do WhatsApp desejado
    const message = encodeURIComponent("Olá, estou com uma dúvida sobre o site.");
    window.open(`https://wa.me/${whatsappNumber}?text=${message}`, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: 3,
      }}
    >
      <Typography variant="h1" color="error" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Página não encontrada
      </Typography>
      <Typography variant="body1" gutterBottom>
        Desculpe, não conseguimos encontrar a página que você está procurando.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<WhatsAppIcon />}
        onClick={handleWhatsAppClick}
        sx={{ mt: 3 }}
      >
        Entre em contato via WhatsApp
      </Button>
    </Box>
  );
};

export default Page404;
